<template>
  <div class="dashboard-container">
    <dashboardSideBar
      class="dashboard-sidebar sidenav shadow-card"
      :sidebarSections="selectedSidBar"
    />

    <div class="dashboard-content col-12 col-lg-9 ml-auto">
      <div class="home-loader-container" v-if="!user">
        <loader></loader>
      </div>

      <div class="card-body" id="my-scrollable-container" v-if="user">
        <adminDashboard
          :userId="user.id"
          v-if="user.isAdmin || user.isManagingAdmin"
        />
        <patientDashboard :userId="user.id" v-if="user.isPatient" />
        <providerDashboard :userId="user.id" v-if="user.isProvider" />
        <referralSourceDashboard :userId="user.id" v-if="user.isReferer" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import adminDashboard from "./admin-dashboard.vue";
import patientDashboard from "./patient-dashboard.vue";
import providerDashboard from "./provider-dashboard.vue";
import referralSourceDashboard from "./referral-source-dashboard.vue";
import dashboardSideBar from "./dashboard-sidebar.vue";
import "../../assets/css/dashboard.css";

export default {
  name: "Index",
  components: {
    adminDashboard,
    patientDashboard,
    providerDashboard,
    referralSourceDashboard,
    dashboardSideBar,
  },
  data() {
    return {
      adminSidebarSections: [
        {
          id: "1",
          displayText: "Monthly Revenue",
          iconName: "summary",
          isActive: true,
          sectionName: "annualRevenue",
        },
        {
          id: "2",
          displayText: "Timeline",
          iconName: "timeline",
          sectionName: "Timeline",
        },
        {
          id: "3",
          displayText: "Revenue by Client",
          iconName: "revenue-by-patient",
          sectionName: "revenueByPatient",
        },
        {
          id: "4",
          displayText: "Revenue by Provider",
          iconName: "revenue-by-provider",
          sectionName: "revenueByProvider",
        },
        {
          id: "5",
          displayText: "Revenue by Service",
          iconName: "revenue-by-service",
          sectionName: "revenueByService",
        },
        {
          id: "6",
          displayText: "Revenue by Referral Source",
          iconName: "revenue-by-referral-source",
          sectionName: "revenueByReferralSource",
        },
        {
          id: "13",
          displayText: "Revenue by Profession",
          iconName: "revenue-by-service",
          sectionName: "revenueByProfession",
        },
        {
          id: "10",
          displayText: "Budget in Hours by Client",
          iconName: "clock",
          sectionName: "maxHoursByClient",
        },
        {
          id: "11",
          displayText: "Budget in $ by Client",
          iconName: "revenue-by-service",
          sectionName: "budgetByClient",
        },
        {
          id: "12",
          displayText: "Monthly Hours",
          iconName: "clock",
          sectionName: "billablePercentage",
        },
        {
          id: "7",
          displayText: "Upcoming Appointments",
          iconName: "upcoming -appointments",
          sectionName: "upcomingAppointments",
        },
        {
          id: "8",
          displayText: "Locations",
          iconName: "locations",
          sectionName: "Locations",
        },
        {
          id: "9",
          displayText: "Tools and Integrations",
          iconName: "clinic-tools-apps",
          sectionName: "clinicalToolsAndApps",
        },
      ],
      managerSidebarSections: [
        {
          id: "1",
          displayText: "Summary",
          iconName: "summary",
          isActive: true,
          sectionName: "Summary",
        },
        {
          id: "2",
          displayText: "Timeline",
          iconName: "timeline",
          sectionName: "Timeline",
        },
        {
          id: "3",
          displayText: "My Clients",
          iconName: "revenue-by-patient",
          sectionName: "myClients",
        },
        {
          id: "4",
          displayText: "My Providers",
          iconName: "revenue-by-provider",
          sectionName: "myProviders",
        },
        {
          id: "8",
          displayText: "Max Hours by Client",
          iconName: "clock",
          sectionName: "maxHoursByClient",
        },
        {
          id: "5",
          displayText: "Upcoming Appointments",
          iconName: "upcoming -appointments",
          sectionName: "upcomingAppointments",
        },
        {
          id: "6",
          displayText: "Locations",
          iconName: "locations",
          sectionName: "Locations",
        },
        {
          id: "7",
          displayText: "Tools and Integrations",
          iconName: "clinic-tools-apps",
          sectionName: "clinicalToolsAndApps",
        },
      ],
      providerSidebarSections: [
        {
          id: "1",
          displayText: "Summary",
          iconName: "summary",
          isActive: true,
          sectionName: "Summary",
        },
        {
          id: "2",
          displayText: "My Clients",
          iconName: "revenue-by-patient",
          sectionName: "myClients",
        },
        {
          id: "3",
          displayText: "Timeline",
          iconName: "timeline",
          sectionName: "Timeline",
        },
        {
          id: "4",
          displayText: "Upcoming Appointments",
          iconName: "upcoming -appointments",
          sectionName: "upcomingAppointments",
        },
        {
          id: "8",
          displayText: "Budget in Hours by Client",
          iconName: "revenue-by-provider",
          sectionName: "budgetInHoursByClient",
        },
        {
          id: "9",
          displayText: "Budget in $ by Client",
          iconName: "clock",
          sectionName: "budgetInDollarByClient",
        },
        {
          id: "5",
          displayText: "Locations",
          iconName: "locations",
          sectionName: "Locations",
        },
        {
          id: "6",
          displayText: "To Do",
          iconName: "todo",
          sectionName: "toDo",
        },
        {
          id: "7",
          displayText: "Tools and Integrations",
          iconName: "clinic-tools-apps",
          sectionName: "clinicalToolsAndApps",
        },
      ],
      patientSidebarSections: [
        {
          id: "1",
          displayText: "My Contacts",
          iconName: "my-contacts",
          isActive: true,
          sectionName: "myContacts",
        },
        {
          id: "2",
          displayText: "Upcoming Appointments",
          iconName: "upcoming -appointments",
          sectionName: "upcomingAppointments",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    selectedSidBar: function () {
      if (this.user) {
        if (this.user.isAdmin) {
          return this.adminSidebarSections;
        } else if (this.user.isManagingAdmin) {
          return this.managerSidebarSections;
        } else if (this.user.isProvider) {
          return this.providerSidebarSections;
        } else {
          return this.patientSidebarSections;
        }
      } else {
        return [];
      }
    },
  },
};
</script>
<style lang="scss">
.dashboard-sidebar {
  position: fixed;
  top: 76px;
  padding-top: 35px;
  padding-bottom: 24px;
  bottom: 0;
  z-index: 1;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
    height: 0;
  }
}

.dashboard-footer {
  @media (min-width: 992px) {
    width: 75%;
    margin-left: 25%;
  }
}

.ribbon-indicator {
  display: none;
}
.dashboard-container {
  background: #ffff;

  .home-loader-container {
    width: 100%;
    height: 65vh;
    position: relative;

    .loader {
      position: absolute;
      background-color: transparent;
    }
  }
}
</style>

<template>
  <div class="budget-updates-container">
    <ul class="nav nav-tabs">
      <li class="nav-item w-50 m-0 pr-5">
        <a
          class="nav-link pointer-cursor text-md-left text-center"
          :class="{
            active: selectedTab === 0,
          }"
          @click="selectedTab = 0"
        >
          <span class="badge badge-pill badge-danger">100%</span>
          <span class="d-md-inline d-none"> exhausted budgets </span>
        </a>
      </li>
      <li class="nav-item w-50 m-0">
        <a
          class="nav-link pointer-cursor text-md-left text-center"
          :class="{
            active: selectedTab === 1,
          }"
          @click="selectedTab = 1"
        >
          <span class="badge badge-pill badge-warning">50%</span>
          <span class="d-md-inline d-none"> exhausted budgets </span>
        </a>
      </li>
    </ul>
    <alert v-if="loading" :spinner="true" class="my-4 shadow-card" />
    <alert
      class="my-4 shadow-card"
      :hideLoader="true"
      v-else-if="!loading && !budgetUpdates.length"
    >
      No Budget Updates
    </alert>
    <div v-else>
      <div
        class="budget-updates-container__tab-content"
        v-if="selectedTab === 0"
      >
        <alert
          v-if="!exhaustedBudgets.length"
          class="my-4 shadow-card"
          :hideLoader="true"
        >
          No budgets to display
        </alert>
        <table
          v-else
          class="
            table table-sm table-striped
            responsive-mobile-tablet-table
            fixed-height-table
            border
          "
        >
          <thead class="fixed-header">
            <tr>
              <th>Client</th>
              <th>Service</th>
              <th>Total Budget</th>
              <th class="text-center">Spending Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="budget in exhaustedBudgets" :key="budget.id">
              <td>
                <b class="mobile-tablet-only mr-2">Client: </b>
                <router-link :to="`/clients/${budget.client_id}/budget`">
                  {{ budget.user.name }}
                </router-link>
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Service: </b>
                {{ budget.service.name }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Total Budget: </b>
                ${{
                  `${parseFloat(budget.budget).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Spending Amount: </b>
                ${{
                  `${parseFloat(budget.spending_amount).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="budget-updates-container__tab-content">
        <alert
          v-if="!fiftyPercentExhaustedBudgets.length"
          class="my-4 shadow-card"
          :hideLoader="true"
        >
          No budgets to display
        </alert>
        <table
          v-else
          class="
            table table-sm table-striped
            responsive-mobile-tablet-table
            fixed-height-table
            border
          "
        >
          <thead class="fixed-header">
            <tr>
              <th>Client</th>
              <th>Service</th>
              <th class="text-center">Total Budget</th>
              <th class="text-center">Spending Amount</th>
              <th class="text-center">Remaining Budget</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="budget in fiftyPercentExhaustedBudgets" :key="budget.id">
              <td>
                <b class="mobile-tablet-only mr-2">Client: </b>
                <router-link :to="`/clients/${budget.client_id}/budget`">
                  {{ budget.user.name }}
                </router-link>
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Service: </b>
                {{ budget.service.name }}
              </td>
              <td class="text-center">
                <b class="mobile-tablet-only mr-2">Total Budget: </b>
                ${{
                  `${parseFloat(budget.budget).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td class="text-center">
                <b class="mobile-tablet-only mr-2">Spending Amount: </b>
                ${{
                  `${parseFloat(budget.spending_amount).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td class="text-center">
                <b class="mobile-tablet-only mr-2">Remaining Budget: </b>
                ${{
                  `${parseFloat(
                    `${budget.budget - budget.spending_amount}`
                  ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "BudgetUpdate",
  data() {
    return {
      selectedTab: 0,
    };
  },
  mounted() {
    this.getBudgetUpdates();
  },
  computed: {
    ...mapState({
      budgetUpdates: (state) => state.patients.budgetUpdates.data,
      loading: (state) => state.patients.budgetUpdates.loading,
    }),
    exhaustedBudgets() {
      return this.budgetUpdates.filter(
        (budget) => budget.spending_amount >= budget.budget
      );
    },
    fiftyPercentExhaustedBudgets() {
      return this.budgetUpdates.filter(
        (budget) =>
          budget.spending_amount >= budget.budget / 2 &&
          budget.spending_amount < budget.budget
      );
    },
  },
  methods: {
    ...mapActions({
      getBudgetUpdates: "patients/getBudgetUpdates",
    }),
  },
};
</script>
<style lang="scss" scoped>
.budget-updates-container {
  .budget-updates-container__tab-content {
    max-height: 400px;
    overflow-y: auto;
  }
  table {
    font-size: 14px;
    .fixed-header {
      position: sticky;
      top: 0;
      background-color: white;
    }
  }
}
</style>

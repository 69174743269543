<template>
  <div>
    <alert v-if="isLoading" />
    <div
      class="card mobile-tablet-no-card"
      v-if="!isLoading && patientData && patientData.length"
    >
      <div class="card-body p-lg-3 pb-5 mobile-tablet-no-card" v-if="!maxItems">
        <div class="form-group mb-0">
          <input
            type="search"
            class="form-control"
            @input="SearchpatientText"
            v-model="searchContent"
            placeholder="Search"
          />
        </div>
      </div>
      <div class="table-container">
        <table class="table table-striped mb-0 responsive-mobile-tablet-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="p in displayedPatients" :key="p.id">
              <td>
                <div
                  class="
                    d-inline-flex
                    align-items-center
                    justify-content-center
                  "
                >
                  <b class="mobile-tablet-only mr-2">Name:</b>
                  <img
                    class="user-avatar-img mr-2 mr-lg-3 rounded-circle"
                    :src="p.profile_picture || profileImagePlaceholder"
                  />
                  <router-link :to="`/clients/${p.id}`"
                    >{{ p.last }}, {{ p.first }}
                  </router-link>
                </div>
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Status:</b>{{ p.status }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="col-md-12" v-if="patientData.length > maxItems">
          <router-link :to="`/clients/`">View More</router-link>
        </div>
      </div>
    </div>
    <alert :hideLoader="true" v-if="!isLoading && !(patientData || []).length">
      No Clients</alert
    >
  </div>
</template>
<script>
import { mapState } from "vuex";
import * as dayjs from "dayjs";

export default {
  name: "Patients",
  props: ["maxItems", "propAdminId"],
  data() {
    return {
      searchContent: "",
      profileImagePlaceholder: require("@/assets/img/avatars/profiles/avatar.jpg"),
    };
  },
  computed: {
    ...mapState({
      patientData: (state) => state.patients.patientUsers.pagination.data,
      isLoading: (state) => state.patients.patientUsers.loading,
    }),
    displayedPatients: function () {
      return this.patientData;
    },
  },
  mounted() {
    this.$store.dispatch("patients/getBasicPatients", {
      status: 0,
      limit: this.maxItems,
      administratorId: this.propAdminId,
    });
  },
  methods: {
    SearchpatientText: function () {
      this.$store.dispatch("patients/search", this.searchContent);
    },
    formattedDate: function (dateToformat) {
      if (dateToformat && dateToformat != "") {
        return dayjs(dateToformat).format("YYYY-MM-DD");
      } else return "";
    },
  },
};
</script>
<style scoped>
.user-avatar-img {
  width: 45px;
  min-width: 45px;
  height: 45px;
}
</style>

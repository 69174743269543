const barChart = {
  series: [
    {
      name: "Revenue in $",
      data: [],
    },
  ],
  annotations: {
    points: [],
  },
  chart: {
    height: 350,
    type: "bar",
    stacked: true,
    events: {
      beforeResetZoom: ({ w }) => {
        return {
          xaxis: {
            min: 1,
            max:
              w.config.series[0].data.length > 16
                ? 16
                : w.config.series[0].data.length,
          },
        };
      },
      beforeZoom: ({ w }, opt) => {
        return {
          xaxis: {
            min: opt.xaxis.min < 1 ? 1 : opt.xaxis.min,
            max:
              opt.xaxis.max > w.config.series[0].data.length
                ? w.config.series[0].data.length
                : opt.xaxis.max,
          },
        };
      },
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      columnWidth: "50%",
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 0,
  },
  colors: ["var(--theme-color,#2c7be5)"],
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  grid: {
    row: {
      colors: ["transparent", "transparent"],
      opacity: 0,
    },
    borderColor: "transparent",
  },
  xaxis: {
    labels: {
      formatter: function (value) {
        return `${value}`;
      },
      rotate: -45,
      hideOverlappingLabels: true,
      showDuplicates: true,
    },
    categories: [],
    tickPlacement: "on",
    min: 1,
    max: 16,
    originMax: 16,
  },
  yaxis: {
    title: {
      text: "",
    },
    labels: {
      formatter: function (value) {
        return `${+parseFloat(value).toFixed(2)}`.replace(
          /(\d)(?=(\d{3})+(?!\d))/g,
          "$1,"
        );
      },
    },
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      return `<div class="apexcharts-tooltip-title" style="font-family: sans-serif; font-size: 14px;">${
        w.config.series[seriesIndex].data[dataPointIndex].fullName ||
        w.config.series[seriesIndex].data[dataPointIndex].x
      }</div>
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                    <span class="apexcharts-tooltip-marker" style="background-color: var(--theme-color);"></span>
                    <div class="apexcharts-tooltip-text" style="font-family: sans-serif; font-size: 14px;">
                        <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                        <span class="apexcharts-tooltip-text-y-label">Revenue in $: </span>
                        <span class="apexcharts-tooltip-text-y-value">${
                          series[seriesIndex][dataPointIndex]
                        }</span>
                        </div>
                    </div>
                </div>
                `;
    },
  },
};
const barChart2 = {
  series: [
    {
      name: "Revenue in $",
      data: [],
    },
  ],
  annotations: {
    points: [],
  },
  chart: {
    height: 350,
    type: "bar",
    stacked: true,
    foreColor: "var(--main-text-color)",
    fontFamily: "'Cerebri Sans', sans-serif",
    events: {
      beforeResetZoom: ({ w }) => {
        return {
          xaxis: {
            min: 1,
            max:
              w.config.series[0].data.length > 12
                ? 12
                : w.config.series[0].data.length,
          },
        };
      },
      beforeZoom: ({ w }, opt) => {
        return {
          xaxis: {
            min: opt.xaxis.min < 1 ? 1 : opt.xaxis.min,
            max:
              opt.xaxis.max > w.config.series[0].data.length
                ? w.config.series[0].data.length
                : opt.xaxis.max,
          },
        };
      },
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      columnWidth: "50%",
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 0,
  },
  colors: ["var(--theme-color,#2c7be5)"],
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  grid: {
    row: {
      colors: ["transparent", "transparent"],
      opacity: 0,
    },
    borderColor: "transparent",
  },
  xaxis: {
    labels: {
      formatter: function (value) {
        return `${value}`;
      },
      rotate: -45,
      hideOverlappingLabels: true,
      showDuplicates: true,
    },
    categories: [],
    tickPlacement: "on",
    max: 12,
    originMax: 12,
  },
  yaxis: {
    title: {
      text: "",
    },
    labels: {
      formatter: function (value) {
        return `${+parseFloat(value).toFixed(2)}`.replace(
          /(\d)(?=(\d{3})+(?!\d))/g,
          "$1,"
        );
      },
    },
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      const value = `${+parseFloat(series[seriesIndex][dataPointIndex]).toFixed(
        2
      )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return `<div class="apexcharts-tooltip-title"
        style="font-family: 'Cerebri Sans', sans-serif; font-size: 14px;">${
          w.config.series[seriesIndex].data[dataPointIndex].fullName ||
          w.config.series[seriesIndex].data[dataPointIndex].x
        }</div>
        <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
        <span class="apexcharts-tooltip-marker" style="background-color: ${
          w.config.series[seriesIndex].data[dataPointIndex].fillColor ||
          "var(--theme-color,#2c7be5)"
        };"></span>
        <div class="apexcharts-tooltip-text" style="font-family: sans-serif; font-size: 14px;">
        <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">Revenue in $: </span>
        <span class="apexcharts-tooltip-text-y-value">${value}</span></div><div class="apexcharts-tooltip-goals-group">
        <span class="apexcharts-tooltip-text-goals-label"></span><span class="apexcharts-tooltip-text-goals-value">
        </span></div><div class="apexcharts-tooltip-z-group"><span class="apexcharts-tooltip-text-z-label">
        </span><span class="apexcharts-tooltip-text-z-value"></span></div>
        </div></div>`;
    },
  },
};
const barChart3 = {
  series: [
    {
      name: "Revenue in $",
      data: [],
    },
  ],
  annotations: {
    points: [],
  },
  chart: {
    height: 350,
    type: "bar",
    stacked: true,
    foreColor: "var(--main-text-color)",
    fontFamily: "'Cerebri Sans', sans-serif",
    events: {
      beforeResetZoom: ({ w }) => {
        return {
          xaxis: {
            min: 1,
            max:
              w.config.series[0].data.length > 16
                ? 16
                : w.config.series[0].data.length,
          },
        };
      },
      beforeZoom: ({ w }, opt) => {
        return {
          xaxis: {
            min: opt.xaxis.min < 1 ? 1 : opt.xaxis.min,
            max:
              opt.xaxis.max > w.config.series[0].data.length
                ? w.config.series[0].data.length
                : opt.xaxis.max,
          },
        };
      },
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      columnWidth: "50%",
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 0,
  },
  colors: ["var(--theme-color,#2c7be5)"],
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  grid: {
    row: {
      colors: ["transparent", "transparent"],
      opacity: 0,
    },
    borderColor: "transparent",
  },
  xaxis: {
    labels: {
      formatter: function (value) {
        return `${value}`;
      },
      rotate: -45,
      hideOverlappingLabels: true,
      showDuplicates: true,
    },
    categories: [],
    tickPlacement: "on",
    max: 16,
    originMax: 16,
  },
  yaxis: {
    show: false,
    title: {
      text: "",
    },
    labels: {
      formatter: function (value) {
        return `${+parseFloat(value).toFixed(2)}`.replace(
          /(\d)(?=(\d{3})+(?!\d))/g,
          "$1,"
        );
      },
    },
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      return `<div class="apexcharts-tooltip-title" style="font-family: 'Cerebri Sans', sans-serif; font-size: 14px;">${
        w.config.series[seriesIndex].data[dataPointIndex].fullName ||
        w.config.series[seriesIndex].data[dataPointIndex].x
      }</div>
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                    <span class="apexcharts-tooltip-marker" style="background-color: var(--theme-color);"></span>
                    <div class="apexcharts-tooltip-text" style="font-family: 'Cerebri Sans', sans-serif; font-size: 14px;">
                        <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                        <span class="apexcharts-tooltip-text-y-label">Revenue in $: </span>
                        <span class="apexcharts-tooltip-text-y-value">${
                          series[seriesIndex][dataPointIndex]
                        }</span>
                        </div>
                    </div>
                </div>
                `;
    },
  },
};
const barChart4 = {
  series: [
    {
      name: "Spending Percentage",
      data: [],
    },
  ],
  chart: {
    height: 350,
    type: "bar",
    stacked: true,
    events: {
      beforeResetZoom: ({ w }) => {
        return {
          xaxis: {
            min: 1,
            max:
              w.config.series[0].data.length > 16
                ? 16
                : w.config.series[0].data.length,
          },
        };
      },
      beforeZoom: ({ w }, opt) => {
        return {
          xaxis: {
            min: opt.xaxis.min < 1 ? 1 : opt.xaxis.min,
            max:
              opt.xaxis.max > w.config.series[0].data.length
                ? w.config.series[0].data.length
                : opt.xaxis.max,
          },
        };
      },
    },
  },
  colors: ["var(--theme-color,#2c7be5)"],
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      columnWidth: "50%",
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 0,
  },
  grid: {
    row: {
      colors: ["transparent", "transparent"],
      opacity: 0,
    },
    borderColor: "transparent",
  },
  xaxis: {
    labels: {
      formatter: function (value) {
        return `${value}`.split("/")[0];
      },
      rotate: -45,
      hideOverlappingLabels: true,
      showDuplicates: true,
    },
    categories: [],
    tickPlacement: "on",
    max: 16,
    originMax: 16,
  },
  yaxis: {
    title: {
      text: "",
    },
    labels: {
      formatter: function (value) {
        return `${+parseFloat(value).toFixed(2)}%`;
      },
    },
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    custom: function ({ seriesIndex, dataPointIndex, w }) {
      return `
      <div class="apexcharts-tooltip-title" style="font-family: 'Cerebri Sans', sans-serif; font-size: 12px;">
        ${
          w.config.series[seriesIndex].data[dataPointIndex].fullName ||
          w.config.series[seriesIndex].data[dataPointIndex].x
        }
      </div>
      <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
          <span class="apexcharts-tooltip-marker" style="background-color: var(--theme-color);"></span>
          <div class="apexcharts-tooltip-text" style="font-family: 'Cerebri Sans', sans-serif; font-size: 12px;">
              <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                <span class="apexcharts-tooltip-text-y-label">Spending percentage for selected month: </span>
                <span class="apexcharts-tooltip-text-y-value">
                  ${w.config.series[seriesIndex].data[dataPointIndex].y}%
                </span>
              </div>
          </div>
      </div>
      <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
          <div class="apexcharts-tooltip-text" style="font-family: 'Cerebri Sans', sans-serif; font-size: 12px;">
              <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                <span class="apexcharts-tooltip-text-y-label">Spending Percentage: </span>
                <span class="apexcharts-tooltip-text-y-value">
                  ${
                    w.config.series[seriesIndex].data[dataPointIndex]
                      .spendingPercentage
                  }%
                </span>
              </div>
          </div>
      </div>
        `;
    },
  },
};
const barChart5 = {
  series: [
    {
      name: "Revenue in $",
      data: [],
    },
  ],
  annotations: {
    points: [],
  },
  chart: {
    height: 350,
    type: "bar",
    stacked: true,
    foreColor: "var(--main-text-color)",
    fontFamily: "'Cerebri Sans', sans-serif",
    events: {
      beforeResetZoom: ({ w }) => {
        return {
          xaxis: {
            min: 1,
            max:
              w.config.series[0].data.length > 16
                ? 16
                : w.config.series[0].data.length,
          },
        };
      },
      beforeZoom: ({ w }, opt) => {
        return {
          xaxis: {
            min: opt.xaxis.min < 1 ? 1 : opt.xaxis.min,
            max:
              opt.xaxis.max > w.config.series[0].data.length
                ? w.config.series[0].data.length
                : opt.xaxis.max,
          },
        };
      },
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      columnWidth: "50%",
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 0,
  },
  colors: ["var(--theme-color,#2c7be5)"],
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  grid: {
    row: {
      colors: ["transparent", "transparent"],
      opacity: 0,
    },
    borderColor: "transparent",
  },
  xaxis: {
    labels: {
      formatter: function (value) {
        return `${value}`;
      },
      rotate: -45,
      hideOverlappingLabels: true,
      showDuplicates: true,
    },
    categories: [],
    tickPlacement: "on",
    max: 16,
    originMax: 16,
  },
  yaxis: {
    title: {
      text: "",
    },
    labels: {
      formatter: function (value) {
        return `${+parseFloat(value).toFixed(2)}`.replace(
          /(\d)(?=(\d{3})+(?!\d))/g,
          "$1,"
        );
      },
    },
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    marker: {
      show: true,
      fillColors: ["var(--theme-color,#2c7be5)"],
    },
    style: {
      fontSize: "14px",
    },
  },
};
const barChartHorizontal = {
  series: [
    {
      name: "Revenue in $",
      data: [],
    },
  ],
  chart: {
    type: "bar",
    height: 350,
    fontFamily: "'Cerebri Sans', sans-serif",
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      horizontal: true,
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
    },
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    borderColor: "transparent",
  },
  colors: ["var(--theme-color,#2c7be5)"],
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  xaxis: {
    categories: [],
    labels: {
      hideOverlappingLabels: true,
      showDuplicates: true,
      formatter: function (value) {
        return `${+parseFloat(value).toFixed(2)}`.replace(
          /(\d)(?=(\d{3})+(?!\d))/g,
          "$1,"
        );
      },
    },
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    style: {
      fontSize: "14px",
    },
    y: {
      formatter: function (value) {
        return `${+parseFloat(value).toFixed(2)}`.replace(
          /(\d)(?=(\d{3})+(?!\d))/g,
          "$1,"
        );
      },
    },
  },
};
const barChartHorizontal2 = {
  series: [
    {
      name: "Revenue in $",
      data: [],
    },
  ],
  chart: {
    type: "bar",
    height: 350,
    fontFamily: "'Cerebri Sans', sans-serif",
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      horizontal: true,
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
    },
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    borderColor: "transparent",
  },
  colors: ["var(--theme-color,#2c7be5)"],
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  xaxis: {
    categories: [],
    labels: {
      hideOverlappingLabels: true,
      showDuplicates: true,
      formatter: function (value) {
        return `${+parseFloat(value).toFixed(2)}`.replace(
          /(\d)(?=(\d{3})+(?!\d))/g,
          "$1,"
        );
      },
    },
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      const value = `${+parseFloat(series[seriesIndex][dataPointIndex]).toFixed(
        2
      )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return `<div class="apexcharts-tooltip-title"
      style="font-family: sans-serif; font-size: 14px;">${
        w.config.series[seriesIndex].data[dataPointIndex].fullName ||
        w.config.series[seriesIndex].data[dataPointIndex].x
      }</div>
      <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
      <span class="apexcharts-tooltip-marker" style="background-color: ${
        w.config.series[seriesIndex].data[dataPointIndex].fillColor ||
        "var(--theme-color,#2c7be5)"
      };"></span>
      <div class="apexcharts-tooltip-text" style="font-family: sans-serif; font-size: 14px;">
      <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">Revenue in $: </span>
      <span class="apexcharts-tooltip-text-y-value">${value}</span></div><div class="apexcharts-tooltip-goals-group">
      <span class="apexcharts-tooltip-text-goals-label"></span><span class="apexcharts-tooltip-text-goals-value">
      </span></div><div class="apexcharts-tooltip-z-group"><span class="apexcharts-tooltip-text-z-label">
      </span><span class="apexcharts-tooltip-text-z-value"></span></div>
      </div></div>`;
    },
  },
};
const barChartHorizontal3 = {
  series: [
    {
      name: "Revenue in $",
      data: [],
    },
  ],
  chart: {
    type: "bar",
    height: 350,
    fontFamily: "'Cerebri Sans', sans-serif",
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      horizontal: true,
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
    },
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    borderColor: "transparent",
  },
  colors: ["var(--theme-color,#2c7be5)"],
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  xaxis: {
    categories: [],
    labels: {
      hideOverlappingLabels: true,
      showDuplicates: true,
      formatter: function (value) {
        return `${+parseFloat(value).toFixed(2)}`.replace(
          /(\d)(?=(\d{3})+(?!\d))/g,
          "$1,"
        );
      },
    },
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    style: {
      fontSize: "14px",
    },
    y: {
      formatter: function (value) {
        return `${+parseFloat(value).toFixed(2)}`.replace(
          /(\d)(?=(\d{3})+(?!\d))/g,
          "$1,"
        );
      },
    },
  },
};
const barChartHorizontal4 = {
  series: [
    {
      name: "Spending Percentage",
      data: [],
    },
  ],
  chart: {
    type: "bar",
    height: 350,
  },
  grid: {
    borderColor: "transparent",
  },
  colors: ["var(--theme-color,#2c7be5)"],
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      horizontal: true,
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
    },
  },
  dataLabels: {
    textAnchor: "start",
    enabled: true,
    formatter: function (val) {
      return `
        ${parseFloat(val).toFixed(2)}%
      `;
    },
    style: {
      colors: ["#000"],
    },
  },
  xaxis: {
    title: {
      text: "",
    },
    categories: [],
    labels: {
      hideOverlappingLabels: true,
      showDuplicates: true,
      formatter: function (value) {
        return `${+parseFloat(value).toFixed(2)}%`;
      },
    },
  },
  yaxis: {
    labels: {
      formatter: function (value) {
        return `${value}`.split("/")[0];
      },
    },
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    custom: function ({ seriesIndex, dataPointIndex, w }) {
      return `
      <div class="apexcharts-tooltip-title" style="font-family: 'Cerebri Sans', sans-serif; font-size: 12px;">
        ${
          w.config.series[seriesIndex].data[dataPointIndex].fullName ||
          w.config.series[seriesIndex].data[dataPointIndex].x
        }
      </div>
      <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
          <span class="apexcharts-tooltip-marker" style="background-color: var(--theme-color);"></span>
          <div class="apexcharts-tooltip-text" style="font-family: 'Cerebri Sans', sans-serif; font-size: 12px;">
              <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                <span class="apexcharts-tooltip-text-y-label">Spending percentage for selected month: </span>
                <span class="apexcharts-tooltip-text-y-value">
                  ${w.config.series[seriesIndex].data[dataPointIndex].y}%
                </span>
              </div>
          </div>
      </div>
      <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
          <div class="apexcharts-tooltip-text" style="font-family: 'Cerebri Sans', sans-serif; font-size: 12px;">
              <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                <span class="apexcharts-tooltip-text-y-label">Spending Percentage: </span>
                <span class="apexcharts-tooltip-text-y-value">
                  ${
                    w.config.series[seriesIndex].data[dataPointIndex]
                      .spendingPercentage
                  }%
                </span>
              </div>
          </div>
      </div>
        `;
    },
  },
};
const barChartHorizontal5 = {
  series: [
    {
      name: "Revenue in $",
      data: [],
    },
  ],
  chart: {
    type: "bar",
    height: 350,
    fontFamily: "'Cerebri Sans', sans-serif",
  },
  grid: {
    borderColor: "transparent",
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      horizontal: true,
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
    },
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["var(--theme-color,#2c7be5)"],
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  xaxis: {
    categories: [],
    labels: {
      hideOverlappingLabels: true,
      showDuplicates: true,
      formatter: function (value) {
        return `${+parseFloat(value).toFixed(2)}`.replace(
          /(\d)(?=(\d{3})+(?!\d))/g,
          "$1,"
        );
      },
    },
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    style: {
      fontSize: "14px",
    },
    y: {
      formatter: function (value) {
        return `${+parseFloat(value).toFixed(2)}`.replace(
          /(\d)(?=(\d{3})+(?!\d))/g,
          "$1,"
        );
      },
    },
  },
};
const areaChart = {
  series: [
    {
      name: "Revenue in $",
      data: [],
    },
  ],
  chart: {
    type: "area",
    stacked: false,
    height: 350,
    foreColor: "var(--main-text-color)",
    fontFamily: "'Cerebri Sans', sans-serif",
    zoom: {
      type: "x",
      enabled: true,
      autoScaleYaxis: true,
    },
    toolbar: {
      autoSelected: "zoom",
    },
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
    colors: ["var(--theme-color,#2c7be5)"],
    strokeColor: "var(--theme-color,#2c7be5)",
  },
  title: {
    text: "",
  },
  stroke: {
    colors: ["var(--theme-color,#2c7be5)"],
  },
  fill: {
    type: "gradient",
    colors: ["var(--theme-color,#2c7be5)"],
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.7,
      opacityTo: 0.3,
      stops: [0, 90, 100],
    },
  },
  yaxis: {
    labels: {
      formatter: function (value) {
        return `${+parseFloat(value).toFixed(2)}`.replace(
          /(\d)(?=(\d{3})+(?!\d))/g,
          "$1,"
        );
      },
    },
    title: {
      text: "$",
      rotate: 0,
    },
  },
  xaxis: {
    tooltip: {
      enabled: false,
      intersect: false,
    },
    labels: {
      hideOverlappingLabels: true,
      showDuplicates: true,
    },
  },
  tooltip: {
    shared: false,
    intersect: false,
    marker: {
      show: true,
      fillColors: ["var(--theme-color,#2c7be5)"],
    },
    style: {
      fontSize: "14px",
    },
    y: {
      formatter: function (value) {
        return `${+parseFloat(value).toFixed(2)}`.replace(
          /(\d)(?=(\d{3})+(?!\d))/g,
          "$1,"
        );
      },
    },
  },
};
const maxBarChartHorizontal = {
  series: [
    {
      name: "Spending in Hours",
      data: [],
    },
  ],
  chart: {
    height: 350,
    type: "bar",
  },
  plotOptions: {
    bar: {
      horizontal: true,
      borderRadius: 8,
      columnWidth: "50%",
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
    },
  },
  grid: {
    borderColor: "transparent",
  },
  colors: ["var(--theme-color,#2c7be5)"],
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  zaxis: {
    title: {
      text: "",
    },
  },
  xaxis: {
    title: {
      text: "Hours",
    },
    labels: {
      showDuplicates: true,
      hideOverlappingLabels: true,
      formatter: function (value) {
        return `${+parseFloat(value).toFixed(2)}`.replace(
          /(\d)(?=(\d{3})+(?!\d))/g,
          "$1,"
        );
      },
    },
  },
  yaxis: {
    labels: {
      formatter: function (value) {
        return `${value}`.split("/")[0];
      },
    },
  },
  dataLabels: {
    textAnchor: "start",
    enabled: true,
    formatter: function (val, { seriesIndex, dataPointIndex, w }) {
      return `
        ${parseFloat(
          (val /
            w.config.series[seriesIndex].data[dataPointIndex].goals?.[0]
              .value) *
            100
        ).toFixed(2)}%
      `;
    },
    style: {
      colors: ["#000"],
    },
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    custom: function ({ seriesIndex, dataPointIndex, w }) {
      return `
  <div class="apexcharts-tooltip-title" style="font-family: 'Cerebri Sans', sans-serif; font-size: 12px;">${
    w.config.series[seriesIndex].data[dataPointIndex].fullName ||
    w.config.series[seriesIndex].data[dataPointIndex].x
  }</div>
  <div class="apexcharts-tooltip-text" style="font-family: 'Cerebri Sans', sans-serif; font-size: 12px;">
    <div class="apexcharts-tooltip-y-group">
      <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
        <span class="apexcharts-tooltip-text-y-label">
          <div>
            <div style="display: flex;">
              <span class="apexcharts-tooltip-marker" style="background-color: ${
                w.config.colors[0]
              };"></span> ${w.config.series[seriesIndex].name}:
            </div>
          </div>
        </span>
        <span class="apexcharts-tooltip-text-y-value">${
          w.config.series[seriesIndex].data[dataPointIndex].y
        }H</span>
      </div>

      <div class="apexcharts-tooltip-goals-group" style="padding: 0px 10px 4px;">
        <span class="apexcharts-tooltip-text-goals-label">
          <div>
            <div style="display: flex;"><span class="apexcharts-tooltip-marker" style="background-color: ${
              w.config.series[seriesIndex].data[dataPointIndex].goals?.[0]
                .strokeColor
            }; height: 3px; border-radius: 0; top: 5px;"></span>${
        w.config.series[seriesIndex].data[dataPointIndex].goals?.[0].name
      }</div>
          </div>
        </span><span class="apexcharts-tooltip-text-goals-value">
          <div>
            <div>${parseFloat(
              w.config.series[seriesIndex].data[dataPointIndex].goals?.[0].value
            ).toFixed(2)}H</div>
          </div>
        </span>
      </div>
      <div class="apexcharts-tooltip-z-group" style="display: flex;justify-content:center"><span class="apexcharts-tooltip-text-z-label"></span><span class="apexcharts-tooltip-text-z-value">${
        w.config.series[seriesIndex].data[dataPointIndex].z
      }</span></div>
    </div>
  </div>
`;
    },
  },
  legend: {
    show: true,
    showForSingleSeries: true,
    customLegendItems: ["Spending", "Budgeted Amount"],
    markers: {
      fillColors: ["var(--theme-color,#2c7be5)", "#d7263d"],
    },
  },
};
const maxBarChart = {
  series: [
    {
      name: "Spending in Hours",
      data: [],
    },
  ],
  dataLabels: {
    enabled: false,
  },
  chart: {
    height: 350,
    type: "bar",
    events: {
      beforeResetZoom: ({ w }) => {
        return {
          xaxis: {
            min: 1,
            max:
              w.config.series[0].data.length > 16
                ? 16
                : w.config.series[0].data.length,
          },
        };
      },
      beforeZoom: ({ w }, opt) => {
        return {
          xaxis: {
            min: opt.xaxis.min < 1 ? 1 : opt.xaxis.min,
            max:
              opt.xaxis.max > w.config.series[0].data.length
                ? w.config.series[0].data.length
                : opt.xaxis.max,
          },
        };
      },
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      columnWidth: "50%",
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
    },
  },
  colors: ["var(--theme-color,#2c7be5)"],
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  zoom: {
    enabled: true,
  },
  stroke: {
    width: 0,
  },
  grid: {
    row: {
      colors: ["transparent", "transparent"],
      opacity: 0,
    },
    borderColor: "transparent",
  },
  xaxis: {
    labels: {
      formatter: function (value) {
        return `${value}`.split("/")[0];
      },
      rotate: -45,
      hideOverlappingLabels: true,
      showDuplicates: true,
    },
    categories: [],
    tickPlacement: "on",
    max: 16,
    originMax: 16,
  },
  yaxis: {
    title: {
      text: "Hours",
    },
    labels: {
      formatter: function (value) {
        return `${+parseFloat(value).toFixed(2)}`.replace(
          /(\d)(?=(\d{3})+(?!\d))/g,
          "$1,"
        );
      },
    },
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    custom: function ({ seriesIndex, dataPointIndex, w }) {
      return `
  <div class="apexcharts-tooltip-title" style="font-family: 'Cerebri Sans', sans-serif; font-size: 12px;">${
    w.config.series[seriesIndex].data[dataPointIndex].fullName ||
    w.config.series[seriesIndex].data[dataPointIndex].x
  }</div>
  <div class="apexcharts-tooltip-text" style="font-family: 'Cerebri Sans', sans-serif; font-size: 12px;">
    <div class="apexcharts-tooltip-y-group">
      <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
        <span class="apexcharts-tooltip-text-y-label">
          <div>
            <div style="display: flex;">
              <span class="apexcharts-tooltip-marker" style="background-color: ${
                w.config.colors[0]
              };"></span> ${w.config.series[seriesIndex].name}:
            </div>
          </div>
        </span>
        <span class="apexcharts-tooltip-text-y-value">${
          w.config.series[seriesIndex].data[dataPointIndex].y
        }H</span>
      </div>
      <div class="apexcharts-tooltip-goals-group" style="padding: 0px 10px 4px;">
        <span class="apexcharts-tooltip-text-goals-label">
          <div>
            <div style="display: flex;"><span class="apexcharts-tooltip-marker" style="background-color: ${
              w.config.series[seriesIndex].data[dataPointIndex].goals?.[0]
                .strokeColor
            }; height: 3px; border-radius: 0; top: 5px;"></span>${
        w.config.series[seriesIndex].data[dataPointIndex].goals?.[0].name
      }</div>
          </div>
        </span><span class="apexcharts-tooltip-text-goals-value">
          <div>
            <div>${parseFloat(
              w.config.series[seriesIndex].data[dataPointIndex].goals?.[0].value
            ).toFixed(2)}H</div>
          </div>
        </span>
      </div>
      <div class="apexcharts-tooltip-series-group apexcharts-active" style="display: flex;padding: 0px 10px 4px;">
        <span class="apexcharts-tooltip-text-y-label">
          Spending Percentage:
        </span>
        <span class="apexcharts-tooltip-text-y-value">${parseFloat(
          (w.config.series[seriesIndex].data[dataPointIndex].y /
            w.config.series[seriesIndex].data[dataPointIndex].goals?.[0]
              .value) *
            100
        ).toFixed(2)}%</span>
      </div>
      <div class="apexcharts-tooltip-z-group" style="display: flex;justify-content:center"><span class="apexcharts-tooltip-text-z-label"></span><span class="apexcharts-tooltip-text-z-value">${
        w.config.series[seriesIndex].data[dataPointIndex].z
      }</span></div>
    </div>
  </div>
`;
    },
  },
  legend: {
    show: true,
    showForSingleSeries: true,
    customLegendItems: ["Spending", "Budgeted Amount"],
    markers: {
      fillColors: ["var(--theme-color,#2c7be5)", "#d7263d"],
    },
  },
};
const circleChart = {
  series: [],
  chart: {
    height: 350,
    width: 550,
    type: "pie",
  },
  legend: {
    fontSize: "15px",
    position: "bottom",
  },
  dataLabels: {
    enabled: true,
  },
  tooltip: {
    enabled: true,
    intersect: false,
    style: {
      fontSize: "15px",
    },
    fixed: {
      enabled: false,
      offsetX: 0,
      offsetY: 0,
    },
    y: {
      formatter: (value) => {
        if (parseInt(value) == value) {
          return `${value}h`;
        } else {
          const hours = parseInt(value);
          const minutes = ((value - hours) * 60).toFixed(0);
          return `${hours}h, ${minutes}m`;
        }
      },
      title: {
        formatter: (seriesName) => seriesName + ":",
      },
    },
  },
  colors: ["var(--theme-color,#2c7be5)", "var(--main-text-color)"],
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  labels: ["Billable", "Non Billable"],
};
const referralSourceBarChart = {
  series: [
    {
      name: "Revenue in $",
      data: [],
    },
  ],
  annotations: {
    points: [],
  },
  chart: {
    height: 350,
    type: "bar",
    foreColor: "var(--main-text-color)",
    fontFamily: "'Cerebri Sans', sans-serif",
    stacked: true,
    toolbar: {
      export: {
        csv: {
          filename: `referral-sources-revenue`,
          headerCategory: "Referral Source",
        },
      },
    },
    events: {
      beforeResetZoom: ({ w }) => {
        return {
          xaxis: {
            min: 1,
            max:
              w.config.series[0].data.length > 16
                ? 16
                : w.config.series[0].data.length,
          },
        };
      },
      beforeZoom: ({ w }, opt) => {
        return {
          xaxis: {
            min: opt.xaxis.min < 1 ? 1 : opt.xaxis.min,
            max:
              opt.xaxis.max > w.config.series[0].data.length
                ? w.config.series[0].data.length
                : opt.xaxis.max,
          },
        };
      },
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      columnWidth: "50%",
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 0,
  },
  colors: ["var(--theme-color,#2c7be5)"],
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  grid: {
    row: {
      colors: ["transparent", "transparent"],
      opacity: 0,
    },
    borderColor: "transparent",
  },
  xaxis: {
    labels: {
      formatter: function (value) {
        return `${value}`;
      },
      rotate: -45,
      hideOverlappingLabels: true,
      showDuplicates: true,
    },
    categories: [],
    tickPlacement: "on",
    max: 16,
    originMax: 16,
  },
  yaxis: {
    title: {
      text: "",
    },
    labels: {
      formatter: function (value) {
        return `${+parseFloat(value).toFixed(2)}`.replace(
          /(\d)(?=(\d{3})+(?!\d))/g,
          "$1,"
        );
      },
    },
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      return `<div class="apexcharts-tooltip-title" style="font-family: sans-serif; font-size: 14px;">${
        w.config.series[seriesIndex].data[dataPointIndex].fullName ||
        w.config.series[seriesIndex].data[dataPointIndex].x
      }</div>
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                    <span class="apexcharts-tooltip-marker" style="background-color: ${
                      w.config.colors[0]
                    };"></span>
                    <div class="apexcharts-tooltip-text" style="font-family: sans-serif; font-size: 14px;">
                        <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                        <span class="apexcharts-tooltip-text-y-label">Revenue in $: </span>
                        <span class="apexcharts-tooltip-text-y-value">${
                          series[seriesIndex][dataPointIndex]
                        }</span>
                        </div>
                    </div>
                </div>
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex; font-family: sans-serif; font-size: 14px;">
                <div class="apexcharts-tooltip-y-group" style="display: flex;padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Percentage of yearly company revenue: </span>
                    <span class="apexcharts-tooltip-text-y-value">${
                      w.config.series[0].data[dataPointIndex].u
                    }%</span>
                </div>
               </div>
                `;
    },
  },
};
const referralSourceBarChartHorizontal = {
  series: [
    {
      name: "Revenue in $",
      data: [],
    },
  ],
  chart: {
    type: "bar",
    height: 350,
    fontFamily: "'Cerebri Sans', sans-serif",
    toolbar: {
      export: {
        csv: {
          filename: `referral-sources-revenue`,
          headerCategory: "Referral Source",
        },
      },
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      horizontal: true,
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
    },
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    borderColor: "transparent",
  },
  colors: ["var(--theme-color,#2c7be5)"],
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  xaxis: {
    categories: [],
    labels: {
      hideOverlappingLabels: true,
      showDuplicates: true,
      formatter: function (value) {
        return `${+parseFloat(value).toFixed(2)}`.replace(
          /(\d)(?=(\d{3})+(?!\d))/g,
          "$1,"
        );
      },
    },
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      return `<div class="apexcharts-tooltip-title" style="font-family: sans-serif; font-size: 14px;">${
        w.config.series[seriesIndex].data[dataPointIndex].fullName ||
        w.config.series[seriesIndex].data[dataPointIndex].x
      }</div>
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                    <span class="apexcharts-tooltip-marker" style="background-color: ${
                      w.config.colors[0]
                    };"></span>
                    <div class="apexcharts-tooltip-text" style="font-family: sans-serif; font-size: 14px;">
                        <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                        <span class="apexcharts-tooltip-text-y-label">Revenue in $: </span>
                        <span class="apexcharts-tooltip-text-y-value">${
                          series[seriesIndex][dataPointIndex]
                        }</span>
                        </div>
                    </div>
                </div>
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex; font-family:  sans-serif; font-size: 14px;">
                <div class="apexcharts-tooltip-y-group" style="display: flex;padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Percentage of yearly company revenue: </span>
                    <span class="apexcharts-tooltip-text-y-value">${
                      w.config.series[0].data[dataPointIndex].u
                    }%</span>
                </div>
               </div>
                `;
    },
  },
};
const budgetBarChartHorizontal = {
  series: [
    {
      name: "Amount spent in $ for selected month",
      data: [],
    },
  ],
  chart: {
    height: 350,
    type: "bar",
  },
  plotOptions: {
    bar: {
      horizontal: true,
      borderRadius: 8,
      columnWidth: "50%",
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
    },
  },
  colors: ["var(--theme-color,#2c7be5)"],
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  xaxis: {
    title: {
      text: "$",
    },
    labels: {
      showDuplicates: true,
      hideOverlappingLabels: true,
      formatter: function (value) {
        return `${+parseFloat(value).toFixed(2)}`.replace(
          /(\d)(?=(\d{3})+(?!\d))/g,
          "$1,"
        );
      },
    },
  },
  yaxis: {
    labels: {
      formatter: function (value) {
        return `${value}`.split("/")[0];
      },
    },
  },
  grid: {
    borderColor: "transparent",
  },
  dataLabels: {
    enabled: true,
    textAnchor: "start",
    formatter: function (val) {
      return `
        $${parseFloat(val).toFixed(2)}
      `;
    },
    style: {
      colors: ["#000"],
    },
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    custom: function ({ seriesIndex, dataPointIndex, w }) {
      const spendingAmount =
        w.config.series[seriesIndex].data[dataPointIndex].spendingAmount;
      const remainingBalance =
        w.config.series[seriesIndex].data[dataPointIndex].remainingBalance;
      const isOverBudget = remainingBalance < 0;
      return `
      <div class="apexcharts-tooltip-title" style="font-family: 'Cerebri Sans', sans-serif; font-size: 12px;">
      ${
        w.config.series[seriesIndex].data[dataPointIndex].fullName ||
        w.config.series[seriesIndex].data[dataPointIndex].x
      }
    </div>
    <div class="apexcharts-tooltip-text" style="font-family: 'Cerebri Sans', sans-serif; font-size: 12px;">
      <div class="apexcharts-tooltip-y-group">
        <div class="apexcharts-tooltip-goals-group" style="padding: 0px 10px 0px;">
          <span class="apexcharts-tooltip-text-goals-label">
            <div>
              <div style="display: flex;">
                <span class="apexcharts-tooltip-marker" style="background-color: ${
                  w.config.series[seriesIndex].data[dataPointIndex].goals?.[0]
                    .strokeColor
                }; height: 3px; border-radius: 0; top: 5px;"></span>${
        w.config.series[seriesIndex].data[dataPointIndex].goals?.[0].name
      }
              </div>
            </div>
          </span>
          <span class="apexcharts-tooltip-text-goals-value">
            <div>
              <div>
                $${parseFloat(
                  w.config.series[seriesIndex].data[dataPointIndex].goals?.[0]
                    .value
                ).toFixed(2)}
              </div>
            </div>
          </span>
        </div>
        <div class="apexcharts-tooltip-series-group apexcharts-active"
          style="order: 1; display: flex; padding: 0px 10px 8px;">
          <span class="apexcharts-tooltip-text-y-label">
            <div>
              <div style="display: flex;">
                <span class="apexcharts-tooltip-marker" style="background-color: ${
                  w.config.colors[0]
                };"></span> ${w.config.series[seriesIndex].name}:
              </div>
            </div>
          </span>
          <span class="apexcharts-tooltip-text-y-value">
            $${w.config.series[seriesIndex].data[dataPointIndex].y}
          </span>
        </div>
        <div class="apexcharts-tooltip-series-group apexcharts-active"
          style="order: 1; display: flex; padding: 0px 10px 8px;">
          <span class="apexcharts-tooltip-text-y-label">
            <div>
              <div style="display: flex;">
                Remaining Balance:
              </div>
            </div>
          </span>
          <span class="apexcharts-tooltip-text-y-value" style="${
            isOverBudget ? "color:#dc3545;" : ""
          }">
            $${remainingBalance} 
            ${isOverBudget ? " (Over Budget)" : ""}
          </span>
        </div>
        <div class="apexcharts-tooltip-series-group apexcharts-active" style="display: flex;padding: 0px 10px 4px;">
            <span class="apexcharts-tooltip-text-y-label">
              Spending Percentage:
            </span>
            <span class="apexcharts-tooltip-text-y-value">
              ${parseFloat(
                (spendingAmount /
                  w.config.series[seriesIndex].data[dataPointIndex].goals?.[0]
                    .value) *
                  100
              ).toFixed(2)}%
            </span>
          </div>
        <div class="apexcharts-tooltip-z-group" style="display: flex;justify-content:center">
          <span class="apexcharts-tooltip-text-z-label"></span>
          <span class="apexcharts-tooltip-text-z-value">${
            w.config.series[seriesIndex].data[dataPointIndex].z
          }
          </span>
        </div>
      </div>
    </div>
      `;
    },
  },
  legend: {
    show: true,
    showForSingleSeries: true,
    customLegendItems: ["Spending for Selected Month", "Budgeted Amount"],
    markers: {
      fillColors: ["var(--theme-color,#2c7be5)", "#d7263d"],
    },
  },
};
const budgetBarChart = {
  series: [
    {
      name: "Amount spent in $ for selected month",
      data: [],
    },
  ],
  dataLabels: {
    enabled: false,
  },
  chart: {
    height: 350,
    type: "bar",
    events: {
      beforeResetZoom: ({ w }) => {
        return {
          xaxis: {
            min: 1,
            max:
              w.config.series[0].data.length > 16
                ? 16
                : w.config.series[0].data.length,
          },
        };
      },
      beforeZoom: ({ w }, opt) => {
        return {
          xaxis: {
            min: opt.xaxis.min < 1 ? 1 : opt.xaxis.min,
            max:
              opt.xaxis.max > w.config.series[0].data.length
                ? w.config.series[0].data.length
                : opt.xaxis.max,
          },
        };
      },
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      columnWidth: "50%",
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
    },
  },
  colors: ["var(--theme-color,#2c7be5)"],
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  zoom: {
    enabled: true,
  },
  stroke: {
    width: 0,
  },
  grid: {
    row: {
      colors: ["transparent", "transparent"],
      opacity: 0,
    },
    borderColor: "transparent",
  },
  xaxis: {
    labels: {
      formatter: function (value) {
        return `${value}`.split("/")[0];
      },
      rotate: -45,
      hideOverlappingLabels: true,
      showDuplicates: true,
    },
    categories: [],
    tickPlacement: "on",
    max: 16,
    originMax: 16,
  },
  yaxis: {
    title: {
      text: "$",
      rotate: 0,
    },
    labels: {
      formatter: function (value) {
        return `${+parseFloat(value).toFixed(2)}`.replace(
          /(\d)(?=(\d{3})+(?!\d))/g,
          "$1,"
        );
      },
    },
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    custom: function ({ seriesIndex, dataPointIndex, w }) {
      const remainingBalance =
        w.config.series[seriesIndex].data[dataPointIndex].remainingBalance;
      const spendingAmount =
        w.config.series[seriesIndex].data[dataPointIndex].spendingAmount;
      const isOverBudget = remainingBalance < 0;
      return `
      <div class="apexcharts-tooltip-title" style="font-family: 'Cerebri Sans', sans-serif; font-size: 12px;">
      ${
        w.config.series[seriesIndex].data[dataPointIndex].fullName ||
        w.config.series[seriesIndex].data[dataPointIndex].x
      }
      </div>
      <div class="apexcharts-tooltip-text" style="font-family: 'Cerebri Sans', sans-serif; font-size: 12px;">
        <div class="apexcharts-tooltip-y-group">
          <div class="apexcharts-tooltip-goals-group" style="padding: 0px 10px 0px;">
            <span class="apexcharts-tooltip-text-goals-label">
              <div>
                <div style="display: flex;">
                  <span class="apexcharts-tooltip-marker" style="background-color: ${
                    w.config.series[seriesIndex].data[dataPointIndex].goals?.[0]
                      .strokeColor
                  }; height: 3px; border-radius: 0; top: 5px;">
                  </span>
                  ${
                    w.config.series[seriesIndex].data[dataPointIndex].goals?.[0]
                      .name
                  }
                </div>
              </div>
            </span>
            <span class="apexcharts-tooltip-text-goals-value">
              <div>
                <div>$${parseFloat(
                  w.config.series[seriesIndex].data[dataPointIndex].goals?.[0]
                    .value
                ).toFixed(2)}
                </div>
              </div>
            </span>
          </div>

          <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex; padding: 0px 10px 8px;">
            <span class="apexcharts-tooltip-text-y-label">
              <div>
                <div style="display: flex;">
                  <span class="apexcharts-tooltip-marker" style="background-color: ${
                    w.config.colors[0]
                  };"></span> ${w.config.series[seriesIndex].name}:
                </div>
              </div>
            </span>
            <span class="apexcharts-tooltip-text-y-value">
              $${w.config.series[seriesIndex].data[dataPointIndex].y}
            </span>
          </div>

          <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex; padding: 0px 10px 8px;">
            <span class="apexcharts-tooltip-text-y-label">
              <div>
                <div style="display: flex;">
                  Remaining Balance:
                </div>
              </div>
            </span>
            <span class="apexcharts-tooltip-text-y-value" style="${
              isOverBudget ? "color:#dc3545;" : ""
            }">
              $${remainingBalance} 
              ${isOverBudget ? " (Over Budget)" : ""}
            </span>
          </div>

          <div class="apexcharts-tooltip-series-group apexcharts-active" style="display: flex;padding: 0px 10px 4px;">
            <span class="apexcharts-tooltip-text-y-label">
              Spending Percentage:
            </span>
            <span class="apexcharts-tooltip-text-y-value">
              ${parseFloat(
                (spendingAmount /
                  w.config.series[seriesIndex].data[dataPointIndex].goals?.[0]
                    .value) *
                  100
              ).toFixed(2)}%
            </span>
          </div>
          <div class="apexcharts-tooltip-z-group" style="display: flex;justify-content:center">
            <span class="apexcharts-tooltip-text-z-label"></span><span class="apexcharts-tooltip-text-z-value">
              ${w.config.series[seriesIndex].data[dataPointIndex].z}
            </span>
          </div>
        </div>
      </div>
      `;
    },
  },
  legend: {
    show: true,
    showForSingleSeries: true,
    customLegendItems: ["Spending for Selected Month", "Budgeted Amount"],
    markers: {
      fillColors: ["var(--theme-color,#2c7be5)", "#d7263d"],
    },
  },
};
export default {
  barChart,
  barChart2,
  barChart3,
  barChart4,
  barChart5,
  barChartHorizontal,
  barChartHorizontal2,
  barChartHorizontal3,
  barChartHorizontal4,
  barChartHorizontal5,
  areaChart,
  maxBarChart,
  maxBarChartHorizontal,
  circleChart,
  referralSourceBarChart,
  referralSourceBarChartHorizontal,
  budgetBarChart,
  budgetBarChartHorizontal,
};

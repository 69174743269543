var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"DashboardSection"},[_c('div',{staticClass:"row m-0 col-12"},[(_vm.user && _vm.user.isManagingAdmin)?_c('div',{staticClass:"col-12 col-lg-7 dashboard-item-container my-0",attrs:{"id":"Summary"}},[_vm._m(0),_c('div',{staticClass:"flex-wrap row m-0"},[_c('div',{staticClass:"col-12 col-sm-6 col-md-12 col-xl-4 mt-4"},[_c('div',{staticClass:"card-container btn-theme text-main font-weight-normal px-3"},[_vm._m(1),(_vm.numberOfAssignedProviders.loading)?_c('div',{staticClass:"text-center"},[_c('i',{staticClass:"fas fa-2x fa-spin fa-circle-notch mr-5"})]):_c('p',{staticClass:"value text-center large"},[_vm._v(" "+_vm._s(_vm.numberOfAssignedProviders.data)+" "),_c('br'),_c('span',{staticClass:"btn btn-sm text-main btn-link",on:{"click":function($event){return _vm.openCardInfo('providers', 'Assigned Providers')}}},[_vm._v("View details")])])])]),_c('div',{staticClass:"col-12 col-sm-6 col-md-12 col-xl-4 mt-4"},[_c('div',{staticClass:"card-container bg-table text-white px-3"},[_vm._m(2),(_vm.numberOfAssignedClients.loading)?_c('div',{staticClass:"text-center"},[_c('i',{staticClass:"fas fa-lg fa-spin fa-circle-notch mr-2"})]):_c('p',{staticClass:"value text-center large"},[_vm._v(" "+_vm._s(_vm.numberOfAssignedClients.data)+" "),_c('br'),_c('span',{staticClass:"btn btn-sm text-white btn-link",on:{"click":function($event){return _vm.openCardInfo('patients', 'Assigned Clients')}}},[_vm._v("View details")])])])]),_c('div',{staticClass:"col-12 col-sm-6 col-md-12 col-xl-4 mt-4"},[_c('div',{staticClass:"card-container bg-white text-main bg-img-card px-3"},[_vm._m(3),(_vm.gettingTotalRevenue)?_c('div',{staticClass:"text-center"},[_c('i',{staticClass:"fas fa-2x fa-spin fa-circle-notch mr-5"})]):_c('p',{staticClass:"value text-center large"},[_vm._v(" "+_vm._s(_vm.totalRevenue.sessions_done)+" ")])])])])]):_vm._e(),(
            _vm.user &&
            _vm.user.isAdmin &&
            _vm.$can({ key: 'billingReport', expectedPermission: 'view' })
          )?_c('div',{staticClass:"col-12 col-lg-7 dashboard-item-container my-0"},[_c('div',{staticClass:"row m-0 justify-content-between dashboard-item-header"},[_c('div',{staticClass:"row mx-0 col-12 p-0 justify-content-between"},[_c('div',{staticClass:"dashboard-item-title"},[_vm._v(" Monthly Revenue "),_c('sup',{staticClass:"dashboard-selected-time"},[_vm._v(_vm._s(_vm.selectedMonthYear.annualRevenue))])]),_c('div',[_c('select-control',{key:_vm.selectedMonthYear.annualRevenue,attrs:{"options":_vm.lastYears,"labelClass":"d-none","wrapClass":"mb-0","disabled":_vm.chartLoading.annualRevenue},on:{"input":function($event){return _vm.getAnnualRevenue(true)}},model:{value:(_vm.selectedMonthYear.annualRevenue),callback:function ($$v) {_vm.$set(_vm.selectedMonthYear, "annualRevenue", $$v)},expression:"selectedMonthYear.annualRevenue"}})],1)]),_c('div',{staticClass:"row mx-0 mb-3 annual-revenue-all",attrs:{"id":_vm.noChartData.annualRevenue ? 'annualRevenue' : ''}},[_c('div',{staticClass:"dashboard-widget-info text-center mr-3 mt-3"},[_c('p',{staticClass:"mb-0 widget-title text-center"},[_vm._v(" "+_vm._s(_vm.selectedMonthYear.annualRevenue)+" Total Revenue ")]),_c('p',{staticClass:"mt-1 mb-0 widget-value"},[_vm._v(" $ "),(
                      _vm.totalAllRevenue[`${_vm.selectedMonthYear.annualRevenue}`] !=
                      undefined
                    )?_c('b',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(`${+parseFloat( _vm.totalAllRevenue[ `${_vm.selectedMonthYear.annualRevenue}` ] || 0 ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")))]):_vm._e(),(
                      _vm.totalAllRevenue[`${_vm.selectedMonthYear.annualRevenue}`] ==
                      undefined
                    )?_c('b',{staticClass:"pl-3"},[_c('i',{staticClass:"fas fa-spin fa-circle-notch"})]):_vm._e()])]),_c('div',{staticClass:"dashboard-widget-info text-center mt-3"},[_c('p',{staticClass:"mb-0 widget-title text-center"},[_vm._v(" "+_vm._s(_vm.selectedMonthYear.annualRevenue - 1)+" Total Revenue ")]),_c('p',{staticClass:"mt-1 mb-0 widget-value"},[_vm._v(" $ "),(
                      _vm.totalAllRevenue[
                        `${_vm.selectedMonthYear.annualRevenue - 1}`
                      ] != undefined
                    )?_c('b',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(`${+parseFloat( _vm.totalAllRevenue[ `${_vm.selectedMonthYear.annualRevenue - 1}` ] || 0 ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")))]):_vm._e(),(
                      _vm.totalAllRevenue[
                        `${_vm.selectedMonthYear.annualRevenue - 1}`
                      ] == undefined
                    )?_c('b',{staticClass:"pl-3"},[_c('i',{staticClass:"fas fa-spin fa-circle-notch"})]):_vm._e()])])])]),(_vm.chartLoading.annualRevenue)?_c('alert'):_vm._e(),(_vm.noChartData.annualRevenue && !_vm.chartLoading.annualRevenue)?_c('alert',{attrs:{"hideLoader":true}},[_vm._v("No data to show")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.noChartData.annualRevenue),expression:"!noChartData.annualRevenue"}],attrs:{"id":"annualRevenue"}})],1):_vm._e(),_c('div',{staticClass:"col-12 col-lg-5 mt-4 mt-lg-0"},[_c('timeline-bar',{attrs:{"userId":_vm.userId,"maxItems":_vm.user && _vm.user.isAdmin ? 3 : 2}})],1),_c('dashboard-appointments',{staticClass:"DashboardItem",attrs:{"maxItems":100}}),(_vm.user && _vm.user.isAdmin)?_c('div',{staticClass:"col-12 dashboard-item-container dashboard-main-container d-flex p-0"},[_c('div',{staticClass:"col-3 menu-container py-4 desktop-only-force d-flex flex-column justify-content-between"},[_c('div',[_vm._m(4),_c('div',{staticClass:"chart-menu"},_vm._l((_vm.revenueMenuItems),function(menuItem){return _c('div',{key:menuItem.name,staticClass:"chart-menu-item pointer-cursor d-flex justify-content-between align-items-center",class:{ selected: menuItem.name === _vm.selectedRevenueName },on:{"click":function($event){return _vm.updateSelectedRevenueChart(menuItem.name)}}},[_c('div',{staticClass:"title-img"},[_c('img',{staticClass:"menu-icon mr-3",attrs:{"src":menuItem.icon}}),_c('span',{staticClass:"title"},[_vm._v(_vm._s(menuItem.title))])]),(menuItem.total)?_c('span',{staticClass:"total"},[_vm._v(_vm._s(menuItem.total))]):_vm._e()])}),0)]),_c('div',{staticClass:"chart-layout justify-content-between d-flex align-items-center mt-5 px-0 pt-5 flex-wrap"},[_c('span',{staticClass:"label pr-4 mb-1 font-weight-bold"},[_vm._v("Chart Layout")]),_c('div',{staticClass:"control radius-99 d-inline-flex"},[_c('button',{staticClass:"btn radius-99 text-main",class:{
                    selected: _vm.selectedRevenueDirection == 'horizontal',
                  },attrs:{"disabled":_vm.chartLoading[_vm.selectedRevenueName]},on:{"click":function($event){return _vm.updateSelectedRevenueLayout('horizontal')}}},[_vm._v(" Horizontal ")]),_c('button',{staticClass:"btn radius-99 text-main right-btn",class:{
                    selected: _vm.selectedRevenueDirection == 'vertical',
                  },attrs:{"disabled":_vm.chartLoading[_vm.selectedRevenueName]},on:{"click":function($event){return _vm.updateSelectedRevenueLayout('vertical')}}},[_vm._v(" Vertical ")])])])]),_c('div',{staticClass:"col-12 col-lg-9 py-4 charts-outer-container"},[_c('div',{staticClass:"row m-0 dashboard-item-header small-display flex-column"},[_c('div',{staticClass:"dashboard-item-title"},[_vm._v("Revenue")]),_c('select-control',{attrs:{"options":_vm.revenueChartOptions,"labelClass":"d-none","inputClass":"font-weight-bold","wrapClass":"m-0 w-100 p-0"},on:{"input":function($event){return _vm.updateChartRender()}},model:{value:(_vm.selectedRevenueName),callback:function ($$v) {_vm.selectedRevenueName=$$v},expression:"selectedRevenueName"}})],1),(
                _vm.user &&
                _vm.user.isAdmin &&
                _vm.$can({ key: 'billingReport', expectedPermission: 'view' })
              )?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedRevenueName == 'revenueByPatient'),expression:"selectedRevenueName == 'revenueByPatient'"}]},[_c('div',{staticClass:"row m-0 justify-content-between dashboard-item-header"},[_c('div',{staticClass:"dashboard-item-title"},[_vm._v(" Revenue by Client "),_c('sup',{staticClass:"dashboard-selected-time"},[_vm._v(_vm._s(_vm.selectedMonthYear.revenueByPatient))]),(!_vm.chartLoading.revenueByPatient)?_c('badge',{staticClass:"ml-3 badge-theme"},[_vm._v(_vm._s(this.activeClientsCount)+" Client"+_vm._s(this.activeClientsCount != 1 ? "s" : ""))]):_vm._e()],1),_c('div',{staticClass:"d-flex mt-2 mb-2 mb-lg-5"},[_c('select-control',{key:_vm.selectedMonthYear.revenueByPatient,attrs:{"options":_vm.lastYears,"labelClass":"d-none","disabled":_vm.chartLoading.revenueByPatient,"wrapClass":"mb-0"},on:{"input":function($event){return _vm.getRevenueByPatient(true)}},model:{value:(_vm.selectedMonthYear.revenueByPatient),callback:function ($$v) {_vm.$set(_vm.selectedMonthYear, "revenueByPatient", $$v)},expression:"selectedMonthYear.revenueByPatient"}})],1)]),(_vm.chartLoading.revenueByPatient)?_c('alert'):_vm._e(),(
                  _vm.noChartData.revenueByPatient &&
                  !_vm.chartLoading.revenueByPatient
                )?_c('alert',{attrs:{"hideLoader":true}},[_vm._v("No data to show")]):_vm._e(),_c('div',{staticClass:"chart-container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.noChartData.revenueByPatient),expression:"!noChartData.revenueByPatient"}],attrs:{"id":"revenueByPatient"}})])],1):_vm._e(),(
                _vm.user &&
                _vm.user.isAdmin &&
                _vm.$can({ key: 'billingReport', expectedPermission: 'view' })
              )?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedRevenueName == 'revenueByProvider'),expression:"selectedRevenueName == 'revenueByProvider'"}]},[_c('div',{staticClass:"row m-0 justify-content-between dashboard-item-header"},[_c('div',{staticClass:"dashboard-item-title"},[_vm._v(" Revenue by Provider "),_c('sup',{staticClass:"dashboard-selected-time"},[_vm._v(_vm._s(_vm.selectedMonthYear.revenueByProvider))]),(!_vm.chartLoading.revenueByProvider)?_c('badge',{staticClass:"ml-3 badge-theme"},[_vm._v(_vm._s(this.activeProvidersCount)+" Provider"+_vm._s(this.activeProvidersCount != 1 ? "s" : ""))]):_vm._e(),_c('div',{staticClass:"h4 ml-1 mt-1"},[_vm._v(" Admins and Managers are included. ")])],1),_c('div',{staticClass:"d-flex mt-2 mb-2 mb-lg-5"},[_c('select-control',{key:_vm.selectedMonthYear.revenueByProvider,attrs:{"options":_vm.lastYears,"labelClass":"d-none","disabled":_vm.chartLoading.revenueByProvider,"wrapClass":"mb-0"},on:{"input":function($event){return _vm.getRevenueByProvider(true)}},model:{value:(_vm.selectedMonthYear.revenueByProvider),callback:function ($$v) {_vm.$set(_vm.selectedMonthYear, "revenueByProvider", $$v)},expression:"selectedMonthYear.revenueByProvider"}})],1)]),(_vm.chartLoading.revenueByProvider)?_c('alert'):_vm._e(),(
                  _vm.noChartData.revenueByProvider &&
                  !_vm.chartLoading.revenueByProvider
                )?_c('alert',{attrs:{"hideLoader":true}},[_vm._v("No data to show")]):_vm._e(),_c('div',{staticClass:"chart-container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.noChartData.revenueByProvider),expression:"!noChartData.revenueByProvider"}],attrs:{"id":"revenueByProvider"}})])],1):_vm._e(),(
                _vm.user &&
                _vm.user.isAdmin &&
                _vm.$can({ key: 'billingReport', expectedPermission: 'view' })
              )?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedRevenueName == 'revenueByService'),expression:"selectedRevenueName == 'revenueByService'"}]},[_c('div',{staticClass:"row m-0 justify-content-between dashboard-item-header"},[_c('div',{staticClass:"dashboard-item-title"},[_vm._v(" Revenue by Service "),_c('sup',{staticClass:"dashboard-selected-time"},[_vm._v(_vm._s(_vm.selectedMonthYear.revenueByService))])]),_c('div',{staticClass:"d-flex mt-2 mb-2 mb-lg-5"},[_c('select-control',{key:_vm.selectedMonthYear.revenueByService,attrs:{"options":_vm.lastYears,"labelClass":"d-none","disabled":_vm.chartLoading.revenueByService,"wrapClass":"mb-0"},on:{"input":function($event){return _vm.getRevenueByService(true)}},model:{value:(_vm.selectedMonthYear.revenueByService),callback:function ($$v) {_vm.$set(_vm.selectedMonthYear, "revenueByService", $$v)},expression:"selectedMonthYear.revenueByService"}}),_c('button',{staticClass:"btn btn-sm btn-theme pb-0 mr-2 ml-3 radius-99",attrs:{"disabled":_vm.chartLoading.revenueByService},on:{"click":function($event){!_vm.chartLoading.revenueByService &&
                        _vm.showHideRevenueSubServices()}}},[_vm._v(" "+_vm._s(_vm.showRevenueSubServices ? "Hide" : "Show")+" sub services "),_c('i',{class:`ml-2 fas ${
                        _vm.showRevenueSubServices ? 'fa-list-alt' : 'fa-list-alt'
                      }`})])],1)]),(_vm.chartLoading.revenueByService)?_c('alert'):_vm._e(),(
                  _vm.noChartData.revenueByService &&
                  !_vm.chartLoading.revenueByService
                )?_c('alert',{attrs:{"hideLoader":true}},[_vm._v("No data to show")]):_vm._e(),_c('div',{staticClass:"chart-container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.noChartData.revenueByService),expression:"!noChartData.revenueByService"}],attrs:{"id":"revenueByService"}})])],1):_vm._e(),(_vm.user && _vm.user.isAdmin)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedRevenueName == 'revenueByReferralSource'),expression:"selectedRevenueName == 'revenueByReferralSource'"}]},[_c('div',{staticClass:"row m-0 justify-content-between dashboard-item-header"},[_c('div',{staticClass:"dashboard-item-title"},[_vm._v(" Revenue by Referral Source "),_c('sup',{staticClass:"dashboard-selected-time"},[_vm._v(_vm._s(_vm.selectedMonthYear.revenueByReferralSource))])]),_c('div',{staticClass:"d-flex mt-2 mb-2 mb-lg-5"},[_c('select-control',{key:_vm.selectedMonthYear.revenueByReferralSource,attrs:{"options":_vm.lastYears,"labelClass":"d-none","disabled":_vm.chartLoading.revenueByReferralSource,"wrapClass":"mb-0"},on:{"input":function($event){return _vm.getRevenueByReferralSource(true)}},model:{value:(_vm.selectedMonthYear.revenueByReferralSource),callback:function ($$v) {_vm.$set(_vm.selectedMonthYear, "revenueByReferralSource", $$v)},expression:"selectedMonthYear.revenueByReferralSource"}})],1)]),(_vm.chartLoading.revenueByReferralSource)?_c('alert'):_vm._e(),(
                  _vm.noChartData.revenueByReferralSource &&
                  !_vm.chartLoading.revenueByReferralSource
                )?_c('alert',{attrs:{"hideLoader":true}},[_vm._v("No data to show")]):_vm._e(),_c('div',{staticClass:"chart-container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.noChartData.revenueByReferralSource),expression:"!noChartData.revenueByReferralSource"}],attrs:{"id":"revenueByReferralSource"}})])],1):_vm._e(),(_vm.user && _vm.user.isAdmin)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedRevenueName == 'revenueByProfession'),expression:"selectedRevenueName == 'revenueByProfession'"}]},[_c('div',{staticClass:"row m-0 justify-content-between dashboard-item-header"},[_c('div',{staticClass:"dashboard-item-title"},[_vm._v(" Revenue by Profession "),_c('sup',{staticClass:"dashboard-selected-time"},[_vm._v(_vm._s(_vm.selectedMonthYear.revenueByProfession))])]),_c('div',{staticClass:"d-flex mt-2 mb-2 mb-lg-5"},[_c('select-control',{key:_vm.selectedMonthYear.revenueByProfession,attrs:{"options":_vm.lastYears,"labelClass":"d-none","disabled":_vm.chartLoading.revenueByProfession,"wrapClass":"mb-0"},on:{"input":function($event){return _vm.getRevenueByProfession(true)}},model:{value:(_vm.selectedMonthYear.revenueByProfession),callback:function ($$v) {_vm.$set(_vm.selectedMonthYear, "revenueByProfession", $$v)},expression:"selectedMonthYear.revenueByProfession"}})],1)]),(_vm.chartLoading.revenueByProfession)?_c('alert'):_vm._e(),(
                  _vm.noChartData.revenueByProfession &&
                  !_vm.chartLoading.revenueByProfession
                )?_c('alert',{attrs:{"hideLoader":true}},[_vm._v("No data to show")]):_vm._e(),_c('div',{staticClass:"chart-container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.noChartData.revenueByProfession),expression:"!noChartData.revenueByProfession"}],attrs:{"id":"revenueByProfession"}})])],1):_vm._e(),_c('div',{staticClass:"chart-layout col-12 justify-content-between d-flex align-items-center px-0 flex-wrap small-display mt-3"},[_c('span',{staticClass:"label pr-4 mb-1 font-weight-bold"},[_vm._v("Chart Layout")]),_c('div',{staticClass:"control radius-99 d-inline-flex"},[_c('button',{staticClass:"btn radius-99 text-main",class:{
                    selected: _vm.selectedRevenueDirection == 'horizontal',
                  },attrs:{"disabled":_vm.chartLoading[_vm.selectedRevenueName]},on:{"click":function($event){return _vm.updateSelectedRevenueLayout('horizontal')}}},[_vm._v(" Horizontal ")]),_c('button',{staticClass:"btn radius-99 text-main right-btn",class:{
                    selected: _vm.selectedRevenueDirection == 'vertical',
                  },attrs:{"disabled":_vm.chartLoading[_vm.selectedRevenueName]},on:{"click":function($event){return _vm.updateSelectedRevenueLayout('vertical')}}},[_vm._v(" Vertical ")])])])])]):_vm._e(),_c('div',{staticClass:"col-12 dashboard-item-container dashboard-main-container d-flex p-0"},[_c('div',{staticClass:"col-3 menu-container py-4 desktop-only-force d-flex flex-column justify-content-between"},[_c('div',[_vm._m(5),_c('div',{staticClass:"chart-menu"},_vm._l((_vm.budgetMenuItems),function(menuItem){return _c('div',{key:menuItem.name,staticClass:"chart-menu-item pointer-cursor d-flex justify-content-between align-items-center",class:{ selected: menuItem.name === _vm.selectedBudgetName },on:{"click":function($event){return _vm.updateSelectedBudgetChart(menuItem.name)}}},[_c('div',{staticClass:"title-img"},[_c('img',{staticClass:"menu-icon mr-3",attrs:{"src":menuItem.icon}}),_c('span',{staticClass:"title"},[_vm._v(_vm._s(menuItem.title))])]),(menuItem.total)?_c('span',{staticClass:"total"},[_vm._v(_vm._s(menuItem.total))]):_vm._e()])}),0)]),_c('div',{staticClass:"chart-layout justify-content-between d-flex align-items-center mt-5 px-0 pt-5 flex-wrap"},[_c('span',{staticClass:"label pr-4 mb-1 font-weight-bold"},[_vm._v("Chart Layout")]),_c('div',{staticClass:"control radius-99 d-inline-flex"},[_c('button',{staticClass:"btn radius-99 text-main",class:{
                    selected: _vm.selectedBudgetDirection == 'horizontal',
                  },attrs:{"disabled":_vm.chartLoading[_vm.selectedBudgetName] ||
                    _vm.selectedBudgetName == 'budgetStatus'},on:{"click":function($event){return _vm.updateSelectedBudgetLayout('horizontal')}}},[_vm._v(" Horizontal ")]),_c('button',{staticClass:"btn radius-99 text-main right-btn",class:{
                    selected: _vm.selectedBudgetDirection == 'vertical',
                  },attrs:{"disabled":_vm.chartLoading[_vm.selectedBudgetName] ||
                    _vm.selectedBudgetName == 'budgetStatus'},on:{"click":function($event){return _vm.updateSelectedBudgetLayout('vertical')}}},[_vm._v(" Vertical ")])])])]),_c('div',{staticClass:"col-12 col-lg-9 py-4 charts-outer-container"},[_c('div',{staticClass:"row m-0 dashboard-item-header small-display flex-column"},[_c('div',{staticClass:"dashboard-item-title"},[_vm._v("Budget")]),_c('select-control',{attrs:{"options":_vm.BudgetChartOptions,"labelClass":"d-none","inputClass":"font-weight-bold","wrapClass":"m-0 w-100 p-0"},on:{"input":function($event){return _vm.updateBudgetChartRender()}},model:{value:(_vm.selectedBudgetName),callback:function ($$v) {_vm.selectedBudgetName=$$v},expression:"selectedBudgetName"}})],1),(
                _vm.user &&
                (_vm.user.isAdmin || _vm.user.isManagingAdmin) &&
                _vm.$can({ key: 'maxHoursReport', expectedPermission: 'view' })
              )?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedBudgetName == 'maxHoursByClient'),expression:"selectedBudgetName == 'maxHoursByClient'"}]},[_c('div',{staticClass:"row m-0 justify-content-between dashboard-item-header"},[_c('div',{staticClass:"dashboard-item-title"},[_vm._v(" Monthly Budget in Hours by Client ")]),_c('div',{staticClass:"d-flex mt-2 mb-2 mb-lg-5"},[_c('select-control',{key:_vm.selectedMonthYear.maxHoursByClient,attrs:{"options":_vm.lastMonths,"labelClass":"d-none","disabled":_vm.chartLoading.maxHoursByClient,"wrapClass":"mb-0"},on:{"input":function($event){return _vm.getMaxhoursByClient(true)}},model:{value:(_vm.selectedMonthYear.maxHoursByClient),callback:function ($$v) {_vm.$set(_vm.selectedMonthYear, "maxHoursByClient", $$v)},expression:"selectedMonthYear.maxHoursByClient"}})],1)]),(_vm.chartLoading.maxHoursByClient)?_c('alert'):_vm._e(),(
                  _vm.noChartData.maxHoursByClient &&
                  !_vm.chartLoading.maxHoursByClient
                )?_c('alert',{attrs:{"hideLoader":true}},[_vm._v("No data to show")]):_vm._e(),_c('div',{staticClass:"chart-container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.noChartData.maxHoursByClient),expression:"!noChartData.maxHoursByClient"}],attrs:{"id":"maxHoursByClient"}})])],1):_vm._e(),(
                _vm.user &&
                (_vm.user.isAdmin || _vm.user.isManagingAdmin) &&
                _vm.$can({ key: 'maxHoursReport', expectedPermission: 'view' })
              )?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedBudgetName == 'budgetByClient'),expression:"selectedBudgetName == 'budgetByClient'"}]},[_c('div',{staticClass:"row m-0 justify-content-between dashboard-item-header"},[_c('div',{staticClass:"dashboard-item-title"},[_vm._v(" Monthly Budget in $ by Client "),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(
                      `The green bars on the chart represent the monthly amount spent
                for the selected month and the red lines represent the total
                budget. <br/> You can see the remaining balance and the spending
                percentage by hovering over the bars.`
                    ),expression:"\n                      `The green bars on the chart represent the monthly amount spent\n                for the selected month and the red lines represent the total\n                budget. <br/> You can see the remaining balance and the spending\n                percentage by hovering over the bars.`\n                    ",modifiers:{"top":true}}],staticClass:"fa fa-xs fa-info-circle pointer-cursor"})]),_c('div',{staticClass:"d-flex mt-2 mb-2 mb-lg-5"},[_c('select-control',{key:_vm.selectedMonthYear.budgetByClient,attrs:{"options":_vm.lastMonths,"labelClass":"d-none","disabled":_vm.chartLoading.budgetByClient,"wrapClass":"mb-0"},on:{"input":function($event){return _vm.getBudgetByClient(true)}},model:{value:(_vm.selectedMonthYear.budgetByClient),callback:function ($$v) {_vm.$set(_vm.selectedMonthYear, "budgetByClient", $$v)},expression:"selectedMonthYear.budgetByClient"}})],1)]),(_vm.chartLoading.budgetByClient)?_c('alert'):_vm._e(),(
                  _vm.noChartData.budgetByClient && !_vm.chartLoading.budgetByClient
                )?_c('alert',{attrs:{"hideLoader":true}},[_vm._v("No data to show")]):_vm._e(),_c('div',{staticClass:"chart-container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.noChartData.budgetByClient),expression:"!noChartData.budgetByClient"}],attrs:{"id":"budgetByClient"}})])],1):_vm._e(),(
                _vm.user &&
                (_vm.user.isAdmin || _vm.user.isManagingAdmin) &&
                _vm.$can({ key: 'maxHoursReport', expectedPermission: 'view' })
              )?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedBudgetName == 'budgetStatus'),expression:"selectedBudgetName == 'budgetStatus'"}]},[_vm._m(6),_c('div',{staticClass:"chart-container",staticStyle:{"min-height":"408px","height":"auto"}},[_c('budget-update',{attrs:{"id":"#budget-updates"}})],1)]):_vm._e(),_c('div',{staticClass:"chart-layout col-12 justify-content-between d-flex align-items-center px-0 flex-wrap small-display mt-3"},[_c('span',{staticClass:"label pr-4 mb-1 font-weight-bold"},[_vm._v("Chart Layout")]),_c('div',{staticClass:"control radius-99 d-inline-flex"},[_c('button',{staticClass:"btn radius-99 text-main kaka",class:{
                    selected: _vm.selectedBudgetDirection == 'horizontal',
                  },attrs:{"disabled":_vm.chartLoading[_vm.selectedBudgetName] ||
                    _vm.selectedBudgetName == 'budgetStatus'},on:{"click":function($event){return _vm.updateSelectedBudgetLayout('horizontal')}}},[_vm._v(" Horizontal ")]),_c('button',{staticClass:"btn radius-99 text-main right-btn",class:{
                    selected: _vm.selectedBudgetDirection == 'vertical',
                  },attrs:{"disabled":_vm.chartLoading[_vm.selectedBudgetName] ||
                    _vm.selectedBudgetName == 'budgetStatus'},on:{"click":function($event){return _vm.updateSelectedBudgetLayout('vertical')}}},[_vm._v(" Vertical ")])])])])]),_c('div',{staticClass:"col-12 dashboard-item-container pb-0 mt-0",attrs:{"id":"Locations"}},[_c('div',{staticClass:"row m-0 justify-content-between dashboard-item-header pb-3 align-items-center"},[_c('div',{staticClass:"dashboard-item-title pb-md-1"},[_vm._v("User locations")]),_c('div',{staticClass:"see-all pointer-cursor btn see-all-btn",on:{"click":_vm.navigateToLocations}},[_vm._v(" See All "),_c('i',{staticClass:"fas fa-chevron-right"})])]),_c('UserLocations',{staticClass:"DashboardItem mapItem overflow-hidden",attrs:{"src":"dashboard"}})],1)],1)])]),_c('modal',{staticClass:"card-info-modal themed-modal",attrs:{"name":"card-info-modal","transition":"pop-out","height":'auto',"width":'650px'}},[_c('div',{staticClass:"position-relative modal-body"},[_c('div',{staticClass:"vm--top-right-slot d-flex align-items-center justify-content-between mb-2",staticStyle:{"z-index":"1"}},[_c('h2',{staticClass:"mb-0 ml-3 text-main"},[_vm._v(_vm._s(_vm.learnMoreInfo.title))]),_c('button',{staticClass:"btn p-2 border-0",on:{"click":_vm.closeCardInfoModal}},[_c('i',{staticClass:"fas fa-times fa-lg"})])]),_c('div',{staticClass:"body-content modal-body-container"},[(_vm.learnMoreInfo.name == 'patients')?_c('Patients',{staticClass:"DashboardItem pl-2 pr-2 pl-lg-0 pr-lg-0",attrs:{"maxItems":99999,"propAdminId":_vm.user.id}}):_vm._e(),(_vm.learnMoreInfo.name == 'providers')?_c('Providers',{staticClass:"DashboardItem pl-2 pr-2 pl-lg-0 pr-lg-0",attrs:{"onlyMe":true,"maxItems":99999}}):_vm._e()],1)])]),(_vm.user && _vm.user.email == 'eng.karam.eqhash@gmail.com')?_c('button',{staticClass:"btn btn-black m-5",on:{"click":function($event){return _vm.oneTimeFix()}}},[_vm._v(" One Time Fix ")]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row m-0 justify-content-between dashboard-item-header"},[_c('div',{staticClass:"row mx-0 col-12 p-0 justify-content-between"},[_c('div',{staticClass:"dashboard-item-title"},[_vm._v("Summary")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center mb-4"},[_c('img',{staticClass:"card-icon mr-3 mb-0 large",attrs:{"src":require("@/assets/img/dashboard/revenue-by-provider-black.svg")}}),_c('p',{staticClass:"text-capitalize title"},[_vm._v("Assigned Providers")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center mb-4"},[_c('img',{staticClass:"card-icon mr-3 mb-0 large",attrs:{"src":require("@/assets/img/dashboard/revenue-by-patient-active.svg")}}),_c('p',{staticClass:"text-capitalize title"},[_vm._v("Assigned Clients")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center mb-4"},[_c('img',{staticClass:"card-icon mr-3 mb-0 large",attrs:{"src":require("@/assets/img/dashboard/sessions-done-black.svg")}}),_c('p',{staticClass:"text-capitalize title"},[_vm._v("Sessions completed")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row m-0 dashboard-item-header"},[_c('div',{staticClass:"dashboard-item-title"},[_vm._v("Revenue By")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row m-0 dashboard-item-header"},[_c('div',{staticClass:"dashboard-item-title"},[_vm._v("Budget")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row m-0 justify-content-between dashboard-item-header"},[_c('div',{staticClass:"dashboard-item-title"},[_vm._v("Budget Status")])])
}]

export { render, staticRenderFns }
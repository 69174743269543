<template>
  <div v-if="dummyDataList.length > 0">
    <div class="card-body p-3">
      <div class="form-group mb-0">
        <input type="search" placeholder="Search" class="form-control" />
      </div>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>ID</th>
          <th>Title</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="p in dummyDataList" :key="p.id">
          <td>{{ p.id }}</td>
          <td>
            <router-link :to="`/clients/${p.id}`"
              >{{ p.Name }} {{ p.id }}</router-link
            >
          </td>
        </tr>
      </tbody>
    </table>
    <div class="col-md-12">
      <router-link :to="`/clients/`">Load more records</router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: "dummyList",

  components: {},
  mounted() {
    this.dummyDataList = [
      {
        id: 1,
        Name: "Item",
      },
      {
        id: 2,
        Name: "Item",
      },
      {
        id: 3,
        Name: "Item",
      },
      {
        id: 4,
        Name: "Item",
      },
      {
        id: 5,
        Name: "Item",
      },
    ];
  },

  data() {
    return {
      dummyDataList: [],
    };
  },
};
</script>
